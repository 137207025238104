import { SETTINGS } from '../../config/settings';

const urlClubePublicationWeb = SETTINGS.public.urlClubePublicationWeb;
const urlPlanWeb = SETTINGS.public.urlPlanWeb;
const urlMedicationsWeb = SETTINGS.public.urlMedicationsWeb;
const urlPosthumousWeb = SETTINGS.public.urlPosthumousWeb;
const urlHealthWeb = SETTINGS.public.urlHealthWeb;
const urlAuthWeb = SETTINGS.public.urlAuthWeb;
const urlContractWeb = SETTINGS.public.urlContractWeb;
const urlAccountWeb = SETTINGS.public.urlAccountWeb;
const urlHubWeb = SETTINGS.public.urlHubWeb;

export const AUTH = urlAuthWeb;
export const ACCOUNT = urlAccountWeb;
export const HUB = urlHubWeb;

export const HUB_HOME = `${HUB}/home`;
export const AUTH_IDENTIFICATION = `${AUTH}/identification`;
export const ACCOUNT_PROFILE = `${AUTH}/profile`;

export const CLUBE = urlClubePublicationWeb;

export const HEALTH = urlHealthWeb;

export const CONTRACT = urlContractWeb;

export const PLAN = urlPlanWeb;

export const MEDICATIONS = urlMedicationsWeb;

export const POSTHUMOUS = urlPosthumousWeb;
