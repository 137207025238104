import {
  PreverAngelusFillXLargeIcon,
  ProductClubeFillXLargeIcon,
  ProductHeathFillXLargeIcon,
  ProductPosthumousFillXLargeIcon,
  token,
} from '@ferreri/prever-component-lib';

import { CLUBE, HEALTH, PLAN, POSTHUMOUS } from '../routes';

export const SERVICES_WEB = {
  plans: {
    icon: <PreverAngelusFillXLargeIcon color={token.colors.neutralXxDark} />,
    title: 'Plano',
    description: 'Associe-se de forma rápida e descomplicada!',
    uri: PLAN,
  },
  clube: {
    icon: (
      <ProductClubeFillXLargeIcon color={token.colors.solidClubePrimaryPure} />
    ),
    title: 'Clube de Vantagens',
    description:
      'Um clube com descontos exclusivos em produtos e serviços de grandes marcas e parceiros locais.',
    uri: CLUBE,
  },
  health: {
    icon: (
      <ProductHeathFillXLargeIcon color={token.colors.solidSaudePrimaryPure} />
    ),
    title: 'Cuidados e Saúde',
    description:
      'Praticidade e descontos especiais na área da saúde para o associado.',
    uri: HEALTH,
  },
  posthumous: {
    icon: (
      <ProductPosthumousFillXLargeIcon
        color={token.colors.solidPostumosPrimaryPure}
      />
    ),
    title: 'Serviços Funerários',
    description:
      'Excelência e humanização no atendimento funeral com cobertura completa.',
    uri: POSTHUMOUS,
  },
};
